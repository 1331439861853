import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Plan from "../components/plan"
import Reviews from "../components/reviews"

const IndexPage = () => (
  <Layout className="home">
    <SEO title="Home" />
    <div className="section-form-review">
      <Plan />
      <Reviews />
    </div>
  </Layout>
)

export default IndexPage
